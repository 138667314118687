import './_form-contact.scss';
import { formInitSelects, formInitContact } from 'JS/components/_contact';

const formContact = function() {
    formInitSelects('.js-form-contact-wrapper');
    formInitContact('.js-form-contact-wrapper', 'contact');
};

document.addEventListener('DOMContentLoaded', function(){
    formContact()
}, false);

window.addEventListener('acf/loaded/form-contact', function (e) {
    formContact()
});